@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Inter, system-ui, sans-serif;
    font-size: 14px;
    @apply text-black dark:text-white dark:bg-black bg-white;
  }
}

.input {
  @apply appearance-none rounded-none relative block w-full px-3 py-2 border border-black dark:border-white dark:bg-black placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10;
}

.button {
  @apply relative w-full flex justify-center py-2 px-4 border text-white bg-black dark:text-black dark:bg-white border-black dark:border-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 align-middle;
}
